<template>
  <v-container>
    <div class="contact">
      <div class="contact__info">
        <div class="mb-3">
          <div class="display-1 mb-2">Contact Info</div>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam perspiciatis dolor, aperiam temporibus assumenda, porro unde distinctio aliquid saepe accusamus numquam</p>
        </div> 
        <div class="contacts d-flex flex-column" style="grid-gap: 12px;">
          <div v-for="(inf, i) in info" :key="i" class="d-flex align-center">
            <div class="contact__circle">
              <v-icon color="white" size="20" v-text="inf.icon" />
            </div>
            <div style="line-height: 1;" class="ml-3">
              <div class="title mb-1" style="line-height: 1;" v-text="inf.head" />  
              <span v-text="inf.title" />
            </div>
          </div>
        </div> 
        <div class="socials mt-6">
          <v-btn icon v-for="(social, s) in socials" :style="{ color: social.color }" large :key="s" target="_blank" :href="social.to">
            <v-icon v-text="social.icon" :style="{ color: social.color }" size="24" />
          </v-btn>
        </div>
      </div>
      <v-form v-model="valid" ref="form" lazy-validation class="form pa-3 pa-sm-4 pa-md-5 pa-lg-6">
        <div class="headline">Message Us</div>
        <v-text-field v-model="form.name" label="Full Name*" :rules="[ v => !!v || '' ]" outlined dense hide-details />
        <v-text-field v-model="form.email" label="Email*" :rules="emailRules" outlined dense hide-details />
        <v-text-field v-model="form.phone" type="number" label="Phone Number*" :rules="mobileRules" outlined dense hide-details /> 
        <v-textarea v-model="form.message" label="Your message..." outlined dense hide-details rows="3" />   
        <v-btn rounded max-width="200" :disabled="!valid" @click.stop="submit" class="white--text">submit</v-btn>  
      </v-form>
    </div>
    <div class="map mt-6">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3554.482279355477!2d84.87663421500014!3d27.01492238308371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39935440af3b59e3%3A0x5aa0411733213e6a!2sBirgunj%20Ghanta%20Ghar!5e0!3m2!1sen!2snp!4v1629135428021!5m2!1sen!2snp" style="border:0;" allowfullscreen="" loading="lazy" />
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    info: [
      { icon: 'mdi-map-marker', title: 'Birgunj, Nepal', head: 'Address' },
      { icon: 'mdi-email', title: 'info@numanchi.com', head: 'Email' },
      { icon: 'mdi-phone', title: '(051) 546 5456', head: 'Phone Number' }
    ],
    socials: [
      { icon: 'mdi-facebook', to: 'https://www.facebook.com', color: '#3b5998' },
      { icon: 'mdi-twitter', to: 'https://www.twitter.com', color: '#00acee'  },
      { icon: 'mdi-linkedin', to: 'https://www.linkedin.com', color: '#0e76a8' },
      { icon: 'mdi-instagram', to: 'https://www.instagram.com', color: '#8a3ab9' }
    ],
    valid: true,
    form: {
      name: null,
      email: null,
      phone: null,
      message: null
    },
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ],
    mobileRules: [
      v => !!v || 'Mobile number is required',
      v => (v && v.length == 10) || '10 digit mobile number is required.'
    ]
  }),
  methods: {
    submit() {
      if(this.$refs.form.validate()) this.$store.dispatch({ type: 'sendMessage', form: this.form }).then(() => {
        if(this.$refs['form']) this.$refs['form'].reset()
      })
    }
  } 
}
</script>

<style scoped lang="scss">
.contact {
  display: grid;
  grid-template-columns: calc(50% - 30px) calc(50% - 30px);
  grid-gap: 60px;
  p, span {
    line-height: 1.3;
    font-size: 13px;  
  }
  .contact__circle {
    height: 48px;
    width: 48px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;  
    background: linear-gradient(45deg, #21a5f7 0%, #b42cd6 100%);
  }
  .form {
    border-radius: 6px;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    .v-btn { background: linear-gradient(45deg, #21a5f7 0%, #b42cd6 100%) !important; }
  }
  @media (max-width: 700px) { grid-template-columns: 100%; grid-gap: 30px; }
}
.map {
  height: 40vh;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  iframe {
    height: 100%;
    width: 100%;
  }
}
</style>